.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* .comment-div .ant-row-bottom{
  width: 125%;
} */

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #3e79f7;
}

.phone-ant-input {
  display: flex;
  align-items: center;
}

.phone-ant-input .PhoneInputInput {
  flex: 1;
  box-sizing: border-box;
  margin: 0px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  padding: 8.5px 11px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  line-height: 1.5;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(230, 235, 241);
  border-radius: 0.625rem;
  transition: all 0.3s ease 0s;
}

.buyerDocuments .ant-image {
  margin-right: 8px;
}

.truncate-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* ------Invoice section-------- */

.invoiceContainer {
  display: flex;
  justify-content: center;
  background-color: gray;
}

.invoiceSection {

  /* width: 794px; */
  /* height: 297mm; */
  /* height: auto; */
  display: flex;
  flex-direction: column;
  /* row-gap: 5px; */
  height: fit-content;
  margin-bottom: 0;

  box-sizing: border-box;
}

.page {
  /* width: 595.28px; */
  /* height: 841.89px; */
  width: 210mm;
  height: 297mm;
  /* height: fit-content; */
  /* height: 842px; */
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  resize: none;
  /* max-height: 297mm; */
  background-color: rgb(255, 255, 255);
  background-image: url('assets/invoice/background.svg');
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid black;
  font-family: 'Inter', sans-serif;
}

.page-break-before {
  page-break-before: always;
  margin-top: 0;
}

.avoid-page-break {
  page-break-inside: avoid;
}

.page-break-after {
  page-break-after: always;
}

/* Invoice Header */
/* measurements used to convert px in design to mm => 1px === 0.3528mm */
.headerContainer {
  width: 100%;
  top: 0;
  left: 0;
}

.topLeftDesign {
  top: 0;
  left: 0;
  width: 25.4116mm;
  height: 11.2873mm;
  position: absolute;
}

.headingWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 13.5232mm;
  padding: 0 14.1176mm 0 12.7058mm;
}

.headingSection {
  display: flex;
  flex-direction: column;
}

.invoiceLogo {
  width: 71.6468mm;
  height: 17.6265mm;
  padding: 0;
}

.placeOfSupply {
  font-size: 3.5228mm;
  font-weight: 400;
  background-color: #EFEFEF;
  padding: 1mm 4mm;
  margin-top: 8.818mm;
  margin-left: 1.4112mm;
  width: fit-content;
  color: #000;
}

/* Invoice footer */
.footerContainer {
  position: absolute;
  width: 100%;
  bottom: 0mm;
  left: 0mm;
  margin-top: 4mm;
}

.systemLine {
  padding: 0;
  margin: 0;
  font-size: 3.1752mm;
  color: #000;
  line-height: 150%;
  margin-bottom: 2.5mm;
  text-align: center;
}

.bottomRightDesign {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30.1326mm;
  height: 19.919mm;
}

.footerFlex {
  display: flex;
  flex-direction: row;
  column-gap: 5.997mm;
  padding-left: 13.053mm;
  padding-right: 9.8784mm;
  padding-bottom: 6.4784mm;
}

.footerBullets {
  height: 16.9344mm;
}

.detailsWrapper {
  display: flex;
  flex-direction: column;
}

.detailsWrapper h1 {
  padding: 0;
  margin: 0;
  font-size: 3.1752mm;
  color: #000;
  line-height: 150%;
  margin-bottom: 1.5mm;
}

.detailsWrapper h1 span {
  margin-right: 8.4672mm;
}

.detailsWrapper h2 {
  padding: 0;
  margin: 0;
  font-size: 3.528mm;
  font-weight: 600;
  color: #000;
  line-height: 150%;
}

/* Invoice details */
.detailsContainer {
  display: flex;
  flex-direction: column;
  padding: 0 14.1176mm;
}

.addressWrapper {
  display: flex;
  flex-direction: row;
  column-gap: 10mm;
  padding: 8.084mm 0;
}

.addressSection {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.sectionTitle {
  font-size: 3.5286mm;
  color: #000;
  margin: 0;
  padding: 0;
}

.sectionName {
  font-size: 3.5286mm;
  font-weight: 700;
  color: #000;
  margin: 2.8224mm 0 0 0;
  padding: 0;
}

.sectionAddress {
  font-size: 3.5286mm;
  font-weight: 400;
  color: #000;
  margin: 2.8224mm 0 0 0;
  padding: 0;
  text-wrap: wrap;
}

.totalLine {
  width: 100%;
  height: 0.6056mm;
  background-color: #E8E8E8;
}

.totalWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 4.292mm 0;
}

.totalFont {
  font-size: 7.056mm;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0 4.5864mm;
}

/* Total and Sign section of invoice */
.totalAndSignContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 37.2848mm;
  padding: 0 14.1176mm;
}

.totalSection {
  min-width: 80.4384mm;
  padding: 0;
  margin-left: auto;
  /* padding: 0 4.2336mm; */
}

.subTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 10.9368mm;
  border-bottom: 0.3528mm solid #ECECEC;
}

.rowTitle {
  font-size: 3.5286mm;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0 0 0 4.2336mm;
}

.rowValue {
  font-size: 3.5286mm;
  font-weight: 500;
  color: #000;
  margin: 0;
  padding: 0 4.2336mm 0 0;
}

.rowValue2 {
  font-size: 3.5286mm;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 0 4.2336mm 0 0;
}

.rowDoubleColon {
  position: absolute;
  left: 50%;
  font-size: 3.5286mm;
  font-weight: 700;
  color: #000;
  margin: 0 0 1mm 0;
  padding: 0 -14.1176mm;
}

/* .subTotalLine {
  width: 100%;
  height: 0.3528mm;
  background-color: #E8E8E8;
} */

.taxTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 10.9368mm;
}

.grandTotal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 10.9368mm;
  background-color: #F5F5F5;
}

/* sign image and name section */
.signSection {
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: relative;
}

.signImage {
  width: 30.6936mm;
  height: 21.168mm;
  /* object-fit: contain !important; */
  margin-bottom: 2mm;
}

.signName {
  font-size: 3.1752mm;
  margin: 0;
  padding: 0;
  color: #000;
}

/* invoice table section */
.invoiceTableSection {
  padding: 0 14.1176mm;
  margin-top: 6.056mm;
  margin-bottom: 7.5256mm;
}

.invoiceTable .ant-table-tbody>tr>td {
  /* remove bottom border of each row */
  border-bottom: 0 !important;
  /* to decrease height of row */
  padding: 2.5224mm 16px !important;
}

.invoiceTable .ant-table-thead .ant-table-cell {
  /* remove bottom border of heading row */
  border-bottom: 0 !important;
}

.invoiceTable .ant-table-thead .ant-table-cell::before {
  /* remove vertical lines b/w column headings */
  height: 0 !important;
}

.invoiceTable .ant-table {
  /* remove white background of rows */
  background: transparent !important;
}

.invoiceTableHigh .ant-table-tbody>tr>td {
  /* remove bottom border of each row */
  border-bottom: 0 !important;
  /* to decrease height of row */
  padding: 16px !important;
}

.invoiceTableHigh .ant-table-thead .ant-table-cell {
  /* remove bottom border of heading row */
  border-bottom: 0 !important;
}

.invoiceTableHigh .ant-table-thead .ant-table-cell::before {
  /* remove vertical lines b/w column headings */
  height: 0 !important;
}

.invoiceTableHigh .ant-table {
  /* remove white background of rows */
  background: transparent !important;
}

.otherRows {
  font-size: 3.528mm;
  font-weight: 500;
  color: #000;
  border-bottom: 0;
  background-color: transparent;
}

.loadingSpinner {
  display: flex;
  justify-content: center;
  margin: 50px 0 100px 0;
}

.button-gap {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}